import * as THREE from "three";
import RegularDie from "./RegularDie";
import {DiceManager, DiceD4} from "threejs-dice";
import CANNON from "cannon";

const getD4Geometry = (radius) => {
    const world = new CANNON.World();
    DiceManager.setWorld(world);
    const d8Model = new DiceD4({ size: radius });
    d8Model.chamfer = 1;
    return d8Model.getGeometry();
};



export default class D4 extends RegularDie {
    constructor(radius = 0.2, options = {}) {
        const geometry = getD4Geometry(radius);
        options.overrideShape = options.overrideShape || geometry.cannon_shape;
        super(options);

        this.radius = radius;
        this.geometry = geometry;
        this.textMargin = 1.2;
        this.faces = [[], [], ['2', '4', '3'], ['1', '3', '4'], ['2', '1', '4'], ['1', '2', '3']];
        this.isD4 = true;

        this.createTextTexture = (text) => {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            const approximateSize = this.radius / 2 + this.radius * 2;
            let ts = Math.max(
                128,
                Math.pow(2, Math.floor(Math.log(approximateSize) / Math.log(2)))
            ) * 2;
            canvas.width = canvas.height = ts;
            context.font = ts / 5 + "pt Arial";
            context.fillStyle = this.options.color.hex();
            context.fillRect(0, 0, canvas.width, canvas.height);
            context.textAlign = "center";
            context.textBaseline = "middle";
            context.fillStyle = this.options.textColor.hex();
            for (let i = 0; i < text.length; i++) {
                context.fillText(text[i], canvas.width / 2,
                    canvas.height / 2 - ts * 0.3);
                context.translate(canvas.width / 2, canvas.height / 2);
                context.rotate(Math.PI * 2 / 3);
                context.translate(-canvas.width / 2, -canvas.height / 2);
            }
            let texture = new THREE.Texture(canvas);
            texture.needsUpdate = true;
            return texture;
        }

        this.create();
    }

    solveFaceUp(index) {
        return index - 1;
    }
}
