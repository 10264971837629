import * as THREE from "three";
import RegularDie from "./RegularDie";
import CANNON from "cannon";
import {DiceD12, DiceManager} from "threejs-dice";

const getD12Geometry = (radius) => {
    const world = new CANNON.World();
    DiceManager.setWorld(world);
    const d8Model = new DiceD12({ size: radius });
    d8Model.chamfer = 1;
    return d8Model.getGeometry();
};

export default class D12 extends RegularDie {
    constructor(radius = 0.2, options = {}) {
        const geometry = getD12Geometry(radius);
        options.overrideShape = options.overrideShape || geometry.cannon_shape;

        super(options);

        this.radius = radius;
        this.geometry = geometry;
        this.textMargin = 1;
        this.faces = [
            " ",
            " ",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
        ];
        this.create();
    }

    solveFaceUp(index) {
        return this.faces[index];
    }
}
