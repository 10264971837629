import * as THREE from "three";
import { DiceManager, DiceD10 } from "threejs-dice";
import CANNON from "cannon";
import RegularDie from "./RegularDie";

const getD10Geometry = (radius) => {
    const world = new CANNON.World();
    DiceManager.setWorld(world);
    const d10Model = new DiceD10({ size: radius });
    d10Model.chamfer = 1;
    return d10Model.getGeometry();
};

export default class D10 extends RegularDie {
    constructor(radius = 0.2, options = {}) {
        const geometry = getD10Geometry(radius);
        options.overrideShape = options.overrideShape || geometry.cannon_shape;

        super(options);

        this.radius = radius;
        this.geometry = geometry
        this.textMargin = 1.2;
        this.faces = [" ", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];

        this.create();
    }

    solveFaceUp(index) {
        return index === 10 ? 10 : this.faces[index];
    }
}
