import * as THREE from "three";
import RegularDie from "./RegularDie";
import CANNON from "cannon";
import {DiceD20, DiceManager} from "threejs-dice";

const getD20Geometry = (radius) => {
    const world = new CANNON.World();
    DiceManager.setWorld(world);
    const d8Model = new DiceD20({ size: radius });
    d8Model.chamfer = 1;
    return d8Model.getGeometry();
};

export default class D1 extends RegularDie {
    constructor(radius = 0.2, options = {}) {
        const geometry = getD20Geometry(radius);
        options.overrideShape = options.ovverrideShape || geometry.cannon_shape;

        super(options);

        this.radius = radius;
        this.geometry = geometry;
        this.textMargin = 1.2;
        this.faces = [" ", " ", "20", "20", "20", "20", "20", "20", "20", "20", "20", "20", "20", "20", "20", "20", "20", "20", "20", "20", "20", "20"];

        this.create();
    }

    solveFaceUp(index) {
        return this.faces[index];
    }
}
