import CANNON from "cannon";

let instance = null;

class WallMaterial {

    constructor() {
        if(!instance)
        {
            this.material = new CANNON.Material();
            instance = this;
        }
        return instance;
    }
}

export default WallMaterial;
