require('./styles/index.scss');
import Popper from 'popper.js';
import jquery from 'jquery';

import DiceScene from "./DiceScene";
import DiceParser from "./DiceParser";

const rollerContainer = document.getElementById("roller-container");
const resultsContainer = document.getElementById("results-container");
const favoriteContainer = document.getElementById("favorite-container");
const favoriteButton = document.getElementById("edit-favorites");
const input = document.getElementById("dice-input");
const slideOut = document.getElementById("myNav");

const localStorage = window.localStorage;
const result = localStorage.getItem('favorites');
const favorites = JSON.parse(result) || [];

addFavoriteButtons();

input.addEventListener("input", changeFavoriteButton);

document.getElementById("dice-form").addEventListener("submit", event => {
    event.preventDefault();
    const parser = new DiceParser(input.value, resultsContainer);
    if(!parser.failed) {
        slideOut.style.width = "100%";
        const diceScene = new DiceScene();
        diceScene.init(rollerContainer, parser.dice, parser);
    } else {
        const errorText = document.getElementById("error-text");
        errorText.innerHTML = parser.failedReason;
        errorText.style.display = "block";
        input.classList.add("is-invalid")
        setTimeout(() => {
            errorText.style.display = "none"
            errorText.innerHTML = "";
            input.classList.remove("is-invalid")
        }, 2000);
    }
})

document.getElementById("closeNav").addEventListener("click", event => {
    slideOut.style.width = "0%";
})

function addFavorite() {
    favorites.push(input.value);
    addFavoriteButtons();
    localStorage.setItem('favorites', JSON.stringify(favorites));
    changeFavoriteButton();

}

function removeFavorite() {
    let index = favorites.indexOf(input.value);
    if(index >= 0) favorites.splice(index, 1);
    addFavoriteButtons();
    localStorage.setItem('favorites', JSON.stringify(favorites));
    changeFavoriteButton();
}

function addFavoriteButtons() {
    const existingButtons = document.querySelectorAll('.favorite-button');
    existingButtons.forEach(e => e.remove());

    favorites.forEach(favorite => {
        const button = document.createElement("button")
        button.classList.add("mr-2", "btn", "btn-lg", "btn-outline-dark", "mb-5", "favorite-button");
        button.innerHTML = favorite;

        button.addEventListener("click", event => {
            event.preventDefault();
            input.value = favorite;
            changeFavoriteButton();
        });

        favoriteContainer.append(button);
    })
}

function changeFavoriteButton() {
    const add = {
        classes: ["btn-outline-success"],
        text: "Add Favorite",
        handler: addFavorite
    }

    const remove = {
        classes: ["btn-outline-danger"],
        text: "Remove Favorite",
        handler: removeFavorite
    }

    if(!input.value) {
        favoriteButton.display = "none";
        return;
    }

    if(favoriteButton.display === "none") favoriteButton.display = "block";

    if(!favorites.includes(input.value)) {
        favoriteButton.classList.remove(...remove.classes);
        favoriteButton.removeEventListener("click", remove.handler)
        favoriteButton.classList.add(...add.classes);
        favoriteButton.innerHTML = add.text;
        favoriteButton.addEventListener("click", add.handler);
    } else {
        favoriteButton.classList.remove(...add.classes);
        favoriteButton.removeEventListener("click", add.handler)
        favoriteButton.classList.add(...remove.classes);
        favoriteButton.innerHTML = remove.text;
        favoriteButton.addEventListener("click", remove.handler);
    }
}

