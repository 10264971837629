import * as Dice from "./Dice/Dice";
import * as math from "mathjs"

class Modifier {
    constructor(operation, value = 1) {
        this.operation = operation;
        this.value = parseInt(value) || 1;
    }

    applyModifier(resultsArray, die) {
        switch(this.operation) {
            case "K":
                return this.keepHighest(resultsArray, this.value);
            case "k":
                return this.keepLowest(resultsArray, this.value);
            case "X":
                return this.keepLowest(resultsArray, resultsArray.length - this.value);
            case "x":
                return this.keepHighest(resultsArray, resultsArray.length - this.value);
            case "w":
                return resultsArray.map(r => die);
            case "l":
                return resultsArray.map(r => 1);
            default:
                return resultsArray;
        }
    }

    keepHighest(resultsArray, number) {
        return resultsArray.sort((a,b) => a-b).slice(resultsArray.length - number).reverse();
    }

    keepLowest(resultsArray, number) {
        return resultsArray.sort((a,b) => a-b).slice(0, number).reverse();
    }
}

class DiceRoll {
    constructor(numberOfDice = 1, die, modifier = false) {
        this.failed = false;
        this.failedReason = "";

        this.numberOfDice = parseInt(numberOfDice);
        this.die = die !== "%" ? parseInt(die) : 100;
        this.modifier = this.parseModifer(modifier);

        if(this.modifier.value > this.numberOfDice) {
            this.failed = true;
            this.failedReason = "Can't have a modifier larger than the number of dice"
        }

        this.diceObjects = this.createDiceObjects(this.numberOfDice, this.die);

        this.diceResults = [];
        this.afterModifer = null;
        this.finalResult = null;
    }

    createDiceObjects(number, die) {
        const diceObjects = [];
        for(let i = 0; i < number; i++) {
            switch(die) {
                case 1:
                    diceObjects.push(new Dice.D1());
                    break;
                case 2:
                    diceObjects.push(new Dice.D2());
                    break;
                case 3:
                    diceObjects.push(new Dice.D3());
                    break;
                case 4:
                    diceObjects.push(new Dice.D4());
                    break;
                case 6:
                    diceObjects.push(new Dice.D6());
                    break;
                case 8:
                    diceObjects.push(new Dice.D8());
                    break;
                case 10:
                    diceObjects.push(new Dice.D10());
                    break;
                case 12:
                    diceObjects.push(new Dice.D12());
                    break;
                case 20:
                    diceObjects.push(new Dice.D20());
                    break;
                case 100:
                    const d100 = new Dice.D100();
                    diceObjects.push(d100);
                    diceObjects.push(new Dice.D10(d100.radius, {
                        color: d100.options.color
                    }));
                    break;
                default:
                    this.failed = true;
                    this.failedReason = "Unrecognised dice type"
                    break;
            }
        }
        return diceObjects;
    }

    parseModifer(modifer) {
        if(modifer === false) return false;
        return new Modifier(modifer.substr(0, 1), modifer.substr(1));
    }

    getResults() {
        for(let i = 0; i < this.diceObjects.length; i++) {
            if (this.die !== 100) {
                this.diceResults.push(this.diceObjects[i].result);
            } else {
                let total = this.diceObjects[i++].result + (this.diceObjects[i].result % 10);
                if(total === 0) total = 100;
                this.diceResults.push(total);
            }
        }
        this.afterModifer = this.modifier ? this.modifier.applyModifier(this.diceResults, this.die) : this.diceResults;
        this.finalResult = this.afterModifer.reduce((a, b) => a + b, 0);
    }
}

export default class DiceParser {
    constructor(inputText, resultContainer) {
        this.failed = false;
        this.failedReason = "";

        this.resultContainer = resultContainer;
        this.resultContainer.style.display = "none";
        this.resultContainer.innerHTML = "";

        this.originalText = inputText;
        this.diceRollText = "";
        this.mathText = "";

        this.diceRolls = this.parseInput(inputText);
        this.dice = [];

        this.finalResult = null;

        this.diceRolls.forEach(roll => {
            roll.diceObjects.forEach(die => this.dice.push(die));
        })

        this.checkIfInvalid()

        if(this.diceRolls.length === 0) {
            this.failed = true;
            this.failedReason = "No dice to roll"
        }
    }

    parseInput(inputText) {
        const matches = [];
        const regex = /(\d*)?d([\d%]*)([kKxXlw]\d*)?/g;
        let newText = inputText;
        let match;
        while(match = regex.exec(inputText)) {
            const diceRoll = new DiceRoll(match[1], match[2], match[3]);
            matches.push(diceRoll);
            newText = newText.replace(match[0], `{${matches.length}}`);
            if(diceRoll.failed)  {
                this.failed = true;
                this.failedReason = diceRoll.failedReason;
            }
        }

        this.diceRollText = newText;
        this.mathText = newText;
        return matches;
    }

    checkIfInvalid() {
        let validityText = this.mathText;

        this.diceRolls.forEach(dr => {
            validityText = validityText.replace(/{\d*}/, 0);
        });

        try {
            math.evaluate(validityText);
        } catch (e) {
            this.failed = true;
            this.failedReason = "Unrecognised characters";
        }
    }

    complete() {
        this.diceRolls.forEach(dr => {
            dr.getResults();
            this.mathText = this.mathText.replace(/{\d*}/, dr.finalResult);
            this.diceRollText = this.diceRollText.replace(/{\d*}/, `[${dr.afterModifer.join(",")}]`);
        });

        this.finalResult = math.evaluate(this.mathText);

        if(this.diceRolls.length === 1 && this.diceRolls[0].numberOfDice === 1) {
            this.resultContainer.innerHTML = `
            <h1 style="font-size: 3em; font-weight: bolder">${this.finalResult}</h1>`
            this.resultContainer.style.display = "flex";
            return;
        }

        const noExtraMath = this.mathText == this.finalResult;
        let resultText = `
            <div style="flex: 1"></div>
            <div style="flex: 1">
                <h1 style="font-size: 3em; font-weight: bolder">${this.finalResult}</h1>
            </div>
            <div style="flex: 2; word-break: break-all">
                <ul>
                    <li><b>${this.originalText}</b></li>
                    <li>= ${this.diceRollText}</li>
                    <li>= ${this.mathText}</li>`

        resultText += noExtraMath ? "</ul></div>" : `<li>= ${this.finalResult}</li></ul></div>`;
        resultText += "<div style=\"flex: 1\"></div>"

        this.resultContainer.innerHTML = resultText
        this.resultContainer.style.display = "flex";
    }
}
