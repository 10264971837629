import RegularDie from "./RegularDie";
import CANNON from "cannon";
import {DiceD6, DiceManager} from "threejs-dice";

const getD6Geometry = (radius) => {
    const world = new CANNON.World();
    DiceManager.setWorld(world);
    const d8Model = new DiceD6({ size: radius });
    d8Model.chamfer = 1;
    return d8Model.getGeometry();
};

export default class D3 extends RegularDie {
    constructor(radius = 0.2, options = {}) {
        const geometry = getD6Geometry(radius);
        options.overrideShape = options.overrideShape || geometry.cannon_shape;
        super(options);

        this.radius = radius;
        this.geometry = geometry;
        this.textMargin = 1;
        this.faces = ["0", "0", "1", "2", "3", "1", "2", "3"];

        this.create();
    }

    solveFaceUp(index) {
        return this.faces[index];
    }
}
