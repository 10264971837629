import * as THREE from "three";
import * as Dice from "./Dice/Dice";
import CANNON from "cannon";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Ground from "./Elements/Ground";
import CannonDebugRenderer from "./DebugRenderer";
import DiceMaterial from "./Materials/Dice";
import WallMaterial from "./Materials/Wall";
import GroundMaterial from "./Materials/Ground";

export default class DiceScene {
    constructor() {
        this.scene = new THREE.Scene();
        this.camera = null;
        this.renderer = null;
        this.world = new CANNON.World();;
        this.running = true;
        this.dice = null;
        this.parser = null;
        this.clock = new THREE.Clock();
        this.dt = 1 / 60.0;
        this.arenaSize = 5;
        this.walls = [
            new Ground(this.arenaSize, 0, this.arenaSize / 2, -this.arenaSize / 2.7, 0, false), // frontwall
            new Ground(this.arenaSize, 0, this.arenaSize / 2, this.arenaSize / 2.7, Math.PI, false), // frontwall
            new Ground(this.arenaSize, 0, 0, 0, -Math.PI / 2, false), // floor
            // new Ground(this.arenaSize, 0, this.arenaSize, 0, -Math.PI / 2, false), // roof
            new Ground(this.arenaSize, this.arenaSize / 2, this.arenaSize / 2, 0, -Math.PI / 2, true), // right wall
            new Ground(this.arenaSize, -this.arenaSize / 2, this.arenaSize / 2, 0, Math.PI / 2, true) // left wall
        ];

        const diceMaterial = new DiceMaterial();
        const wallMaterial = new WallMaterial();
        const groundMaterial = new GroundMaterial();

        this.world.addContactMaterial(new CANNON.ContactMaterial(
            diceMaterial.material, diceMaterial.material, {
                friction: 0,
                restitution: 0.5
            }
        ));

        this.world.addContactMaterial(new CANNON.ContactMaterial(
            groundMaterial.material, diceMaterial.material, {
                friction: 0.01,
                restitution: 0.2
            }
        ));

        this.world.addContactMaterial(new CANNON.ContactMaterial(
            wallMaterial.material, diceMaterial.material, {
                friction: 0,
                restitution: 0.5
            }
        ));
    }

     init(container, diceArray = [], parserHolder) {
        this.dice = diceArray;
        this.parser = parserHolder;

        const SCREEN_WIDTH = container.offsetWidth,
            SCREEN_HEIGHT = container.offsetHeight;

        const VIEW_ANGLE = 45,
            ASPECT = SCREEN_WIDTH / SCREEN_HEIGHT,
            NEAR = 0.1,
            FAR = 100;

        console.log(ASPECT / 1.3)
        this.camera = new THREE.PerspectiveCamera(VIEW_ANGLE, ASPECT, NEAR, FAR);
        this.scene.add(this.camera);

        const cameraHeight = 6 / Math.min(ASPECT / 1.3, 1);
        this.camera.position.set(0,cameraHeight,0);
        this.camera.lookAt(this.scene.position);

        this.renderer = new THREE.WebGLRenderer({ antialias: true });
        this.renderer.setSize(SCREEN_WIDTH, SCREEN_HEIGHT);

        container.innerHTML = "";
        container.appendChild(this.renderer.domElement);

        let controls = new OrbitControls(this.camera, this.renderer.domElement);

        const keyLight = new THREE.PointLight(0xffffff, 2.0);
        keyLight.position.set(cameraHeight * Math.sin(Math.PI / 4), cameraHeight * Math.cos(Math.PI / 4), 0);
        keyLight.castShadow = true;
        this.scene.add(keyLight);

        const fillLight = new THREE.PointLight(0xffffff, 1.5);
        fillLight.position.set(cameraHeight * Math.sin(Math.PI / 4) * -1, cameraHeight * Math.cos(Math.PI / 4), 0);
        fillLight.castShadow = true;
        this.scene.add(fillLight);

        const backLight = new THREE.AmbientLight(0xffffff, 0.2);
        backLight.position.set(0, -cameraHeight, 0);
        backLight.castShadow = true;
        this.scene.add(backLight);

        this.scene.background = new THREE.Color(0xffffff);

        this.walls.forEach(w => this.scene.add(w.mesh));
        this.dice.forEach((die) => this.scene.add(die.mesh));

        this.initPhysics();
        this.animate();
    }


    initPhysics() {
        this.world.broadphase = new CANNON.NaiveBroadphase();
        this.world.solver.iterations = 20;
        this.world.gravity.set(0, -9.8, 0);
        this.world.add(new CANNON.Body());
        this.dice.forEach((die) => {
            die.throw(this.arenaSize);
            this.world.addBody(die.physicsBody);
        });
        this.walls.forEach(w => this.world.addBody(w.physicsBody));
    }

    animate() {
        if(this.running) {
            this.update();
            this.render();
            requestAnimationFrame(this.animate.bind(this));
        }
    }

    update() {
        let totalElapsedTime = this.clock.getElapsedTime();

        // let time = (new Date()).getTime();
        // let timeDiff = (time - lastTime) / 1000;
        // if (timeDiff > 3) timeDiff = dt;
        this.world.step(this.dt);

        this.walls.forEach(w => w.updateCoordinates());

        if(this.running) {
            let isRunning = false;

            this.dice.forEach((die) =>  {
                if(!die.isFinished()) isRunning = true;
                die.updateCoordinates();
            });


            if(!isRunning || totalElapsedTime > 10) {
                if(totalElapsedTime > 10) console.log("Timed Out")
                this.dice.forEach(d => d.getUpsideValue());
                console.log("DONE")
                this.running = false;
                this.parser.complete();
            }
        }
    }

    render() {
        this.renderer.render(this.scene, this.camera);
    }

}

