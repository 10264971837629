export { default as D1 } from "./D1";
export { default as D2 } from "./D2";
export { default as D3 } from "./D3";
export { default as D4 } from "./D4";
export { default as D6 } from "./D6";
export { default as D8 } from "./D8";
export { default as D10 } from "./D10";
export { default as D12 } from "./D12";
export { default as D20 } from "./D20";
export { default as D100 } from "./D100";
