import CANNON from "cannon";
import * as THREE from "three";
import GroundMaterial from "../Materials/Ground";
import WallMaterial from "../Materials/Wall";
import chroma from "chroma-js";

export default class Ground {
    constructor(size = 1, x = 0, y = 0, z = 0, rotation = Math.PI / 2, pivot = false) {
        this.x = x;
        this.y = y;
        this.z = z;
        this.size = size;
        this.rotation = rotation;
        this.pivot = pivot;
        this.mesh = this.getMesh();
        this.physicsBody = this.getPhysicsBody();
    }

    updateCoordinates() {
        this.mesh.position.copy(this.physicsBody.position);
        this.mesh.quaternion.copy(this.physicsBody.quaternion);
    }

    getMesh() {
        const geometry = new THREE.PlaneGeometry(this.size * 1.2, this.size * 1.2, 1, 1);
        const material = new THREE.MeshBasicMaterial({
            color: 0xffffff,
            side: THREE.DoubleSide7,
        });
        const mesh = new THREE.Mesh(geometry, material);
        return mesh;
    }

    getPhysicsBody() {
        const plane = new CANNON.Plane();

        const materialObj = (this.x ===  0 && this.y === 0 && this.z === 0) ? new GroundMaterial() : new WallMaterial();
        const material = materialObj.material;
        const body = new CANNON.Body({
            mass: 0,
            shape: plane,
            material,
        });
        body.quaternion.setFromAxisAngle(
            new CANNON.Vec3(this.pivot ? 0 : 1, this.pivot ? 1 : 0, 0),
            this.rotation
        );
        body.position.x = this.x;
        body.position.y = this.y;
        body.position.z = this.z;
        return body;
    }
}
