import * as THREE from "three";
import { UVsDebug } from "three/examples/jsm/utils/UVsDebug";
import CANNON from "cannon";
import chroma from "chroma-js";
import DiceMaterial from "../Materials/Dice";

const getBestTextColor = (color) => {
    // if (chroma.contrast(color, "white") >= 4.5) return color.brighten(2);
    // return color.darken(2);

    // if (chroma.contrast(color, "white") >= 4.5) return chroma("white");
    return chroma("black");
};

const randomBrightColor = () => {
    let color = chroma.random();
    while(chroma.contrast(color, "black") < 4.5) color = color.brighten();
    return color;
}

export default class RegularDie {

    constructor(options = {}) {
        const defaultOptions = {
            color: options.color || randomBrightColor(),
            opacity: 1,
            x: 0,
            y: 0,
            z: 0,
            overrideShape: false,
            overrideMaterial: false
        };

        this.options = { ...defaultOptions, ...options };

        this.options.textColor =
            this.options.textColor || getBestTextColor(this.options.color);

        this.result = null;
    }

    create() {
        this.mesh = this.getMesh();
        this.physicsBody = this.getPhysicsBody();
    }

    updateCoordinates() {
        this.mesh.position.x = this.physicsBody.position.x;
        this.mesh.position.y = this.physicsBody.position.y;
        this.mesh.position.z = this.physicsBody.position.z;
        this.mesh.setRotationFromQuaternion(this.physicsBody.quaternion);
    }

    getMesh() {
        const group = new THREE.Group();
        const geometry = this.geometry;

        const material = this.options.overrideMaterial || this.getMaterial();
        const mesh = new THREE.Mesh(geometry, material);
        const wireframe = new THREE.LineSegments(
            new THREE.EdgesGeometry(geometry),
            new THREE.LineBasicMaterial({
                color: this.options.color.darken().hex(),
            })
        );
        group.add(mesh);
        group.add(wireframe);
        return group;
    }

    getPhysicsBody() {
        const vertices = this.mesh.children[0].geometry.vertices.map(
            (v) => new CANNON.Vec3(v.x, v.y, v.z)
        );

        const faces = this.mesh.children[0].geometry.faces.map((f) => [
            f.a,
            f.b,
            f.c,
        ]);

        const shape =
            this.options.overrideShape ||
            new CANNON.ConvexPolyhedron(vertices, faces);


        const diceMaterial = new DiceMaterial();
        const body = new CANNON.Body({
            mass: 1,
            material: diceMaterial.material,
        });

        body.linearDamping = 0.1;
        body.angularDamping = 0.1;

        body.position.set(this.options.x, this.options.y, this.options.z);
        body.addShape(shape);
        return body;
    }

    getMaterial() {
        const materials = [];
        const materialOptions = {
            color: this.options.color.hex(),
            side: THREE.DoubleSide,
            opacity: this.options.opacity,
            transparent: true,
        };
        for (let i = 0; i < this.faces.length; ++i) {
            materialOptions.map = this.createTextTexture(this.faces[i]);
            materials.push(new THREE.MeshLambertMaterial(materialOptions));
        }
        return materials;
    }

    createTextTexture(text) {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const approximateSize = this.radius / 2 + this.radius * this.textMargin;
        const textSize =
            Math.max(
                128,
                Math.pow(2, Math.floor(Math.log(approximateSize) / Math.log(2)))
            ) * 2;
        canvas.width = canvas.height = textSize;
        context.font = textSize / (1 + 2 * this.textMargin) + "pt Arial";
        context.fillStyle = this.options.color.hex();
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.textAlign = "center";
        context.textBaseline = "middle";
        context.fillStyle = this.options.textColor.hex();
        context.fillText(text, canvas.width / 2, canvas.height / 2);

        let texture = new THREE.Texture(canvas);
        texture.needsUpdate = true;
        return texture;
    }

    isFinished() {
        let threshold = 0.01;

        let angularVelocity = this.physicsBody.angularVelocity;
        let velocity = this.physicsBody.velocity;

        return (Math.abs(angularVelocity.x) < threshold && Math.abs(angularVelocity.y) < threshold && Math.abs(angularVelocity.z) < threshold &&
            Math.abs(velocity.x) < threshold && Math.abs(velocity.y) < threshold && Math.abs(velocity.z) < threshold);
    }

    getUpsideValue() {
        let vector = new THREE.Vector3(0, this.isD4 ? -1 : 1);
        let closest_face;
        let closest_angle = Math.PI * 2;
        for (let i = 0; i < this.mesh.children[0].geometry.faces.length; ++i) {
            let face = this.mesh.children[0].geometry.faces[i];
            if (face.materialIndex === 0) continue;

            let angle = face.normal.clone().applyQuaternion(this.physicsBody.quaternion).angleTo(vector);
            if (angle < closest_angle) {
                closest_angle = angle;
                closest_face = face;
            }
        }

        this.result = parseInt(this.solveFaceUp(closest_face.materialIndex));
        return this.result;
    }

    throw(arenaSize) {
        // this.physicsBody.position.z = this.physicsBody.position.z + arenaSize;
        // this.physicsBody.position.x = Math.random() * arenaSize - arenaSize / 2;
        // this.physicsBody.position.y = 2;

        const angle = Math.random() * Math.PI * 2;
        this.physicsBody.position.z = Math.cos(angle) * arenaSize / 4;
        this.physicsBody.position.x = Math.sin(angle) * arenaSize / 4;
        this.physicsBody.position.y = 1;


        this.physicsBody.quaternion.x = (Math.random()*90-45) * Math.PI / 180;
        this.physicsBody.quaternion.y = (Math.random()*90-45) * Math.PI / 180;
        this.physicsBody.quaternion.z = (Math.random()*90-45) * Math.PI / 180;

        const xScale = 10;
        const yScale = 1;
        const zScale = 10;
        let yRand = (Math.random() * yScale)
        let xRand = (Math.random() * xScale * 2) - xScale;
        let zRand = (Math.random() * zScale * 2) - zScale;

        this.physicsBody.velocity.set(xRand, yRand, zRand);
        this.physicsBody.angularVelocity.set(Math.random() - 0.5, Math.random() - 0.5, Math.random() - 0.5);
    }
}
